<template>
  <Modal
    :value="value"
    :title="title"
    @input="(val) => $emit('input', val)"
    @on-visible-change="modalShow"
    :width="900"
  >
    <Form ref="form" :model="form" :rules="rules" :label-width="120">
      <Row>
        <Col span="11">
          <FormItem label="基地场景" prop="selBaseSence">
            <Cascader :data="baseSence" v-model="form.selBaseSence"></Cascader>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="监测点名称" prop="groupName">
            <Input v-model="form.groupName"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="监测名称" prop="name">
            <Input v-model="form.name"></Input>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="场景/监测类型" prop="selSensorType">
            <Cascader
              :data="sensorTypeOptions"
              :load-data="getSensorCategoryList"
              v-model="form.selSensorType"
            ></Cascader>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="罗拉设备" prop="unicom">
            <Select v-model="form.unicom">
              <Option
                v-for="(item, index) in llOptions"
                :value="`${item.value}/${item.label}`"
                :key="index"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="通道类型" prop="channelTypeArr">
            <Select
              v-model="form.channelTypeArr"
              placeholder="请先选择基地场景"
              :disabled="form.selBaseSence.length > 0 ? false : true"
            >
              <Option
                v-for="(item, index) in channelTypeOptions"
                :value="`${item.value}/${item.label}`"
                :key="index"
                >{{ item.label }}</Option
              >
            </Select>
            <!-- <Cascader
              :data="channelTypeOptions"
              :load-data="
                (item, cb) => {
                  if (!row) {
                    getChannelList(item, cb, {});
                  } else {
                    getChannelList(item, cb, {
                      id: row.id,
                      type: row.channelType,
                      no: row.channelNo,
                      name: row.channelName,
                      guid: row.guid,
                    });
                  }
                }
              "
              v-model="form.channelTypeArr"
              placeholder="请先选择基地场景"
              :disabled="form.selBaseSence.length > 0 ? false : true"
              @on-change="channelTypeChange"
            ></Cascader> -->
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="通道号" prop="channelNo">
            <Input v-model="form.channelNo"></Input>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="通道号节点" prop="channelNoNode">
            <Input v-model="form.channelNoNode"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="监测值范围" prop="categoryMinRange">
            <div style="display: flex">
              <InputNumber
                v-model="form.categoryMinRange"
                :step="0.1"
                style="width: 150px"
              ></InputNumber>
              ~
              <InputNumber
                v-model="form.categoryMaxRange"
                :step="0.1"
                style="width: 150px"
              ></InputNumber>
            </div>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="量程范围" prop="minRange">
            <div style="display: flex">
              <InputNumber
                v-model="form.minRange"
                :step="0.1"
                style="width: 150px"
                :disabled="
                  form.channelTypeArr && form.channelTypeArr.split('/')[0] == 1
                    ? false
                    : true
                "
              ></InputNumber>
              ~
              <InputNumber
                v-model="form.maxRange"
                :step="0.1"
                style="width: 150px"
                :disabled="
                  form.channelTypeArr && form.channelTypeArr.split('/')[0] == 1
                    ? false
                    : true
                "
              ></InputNumber>
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="微安值范围" prop="minRangeMicro">
            <div style="display: flex">
              <InputNumber
                v-model="form.minRangeMicro"
                :step="0.1"
                style="width: 150px"
                :disabled="
                  form.channelTypeArr && form.channelTypeArr.split('/')[0] == 1
                    ? false
                    : true
                "
              ></InputNumber>
              ~
              <InputNumber
                v-model="form.maxRangeMicro"
                :step="0.1"
                style="width: 150px"
                :disabled="
                  form.channelTypeArr && form.channelTypeArr.split('/')[0] == 1
                    ? false
                    : true
                "
              ></InputNumber>
            </div>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="百分比(%)" prop="percentage">
            <Input
              v-model="form.percentage"
              :disabled="
                form.channelTypeArr && form.channelTypeArr.split('/')[0] == 4
                  ? false
                  : true
              "
            ></Input>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="读取位数" prop="num">
            <Input
              v-model="form.num"
              :disabled="
                form.channelTypeArr && form.channelTypeArr.split('/')[0] == 4
                  ? false
                  : true
              "
            ></Input>
          </FormItem>
        </Col>
        <Col span="11" offset="1">
          <FormItem label="备注" prop="remark">
            <Input v-model="form.remark"></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <p slot="footer" style="text-align: center">
      <Button type="primary" :loading="loading" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    baseSence: {
      type: Array,
      default() {
        return [];
      },
    },
    sensorType: {
      type: Array,
      default() {
        return [];
      },
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    let _self = this;
    return {
      sensorTypeOptions: [],
      llOptions: [],
      channelTypeOptions: [],
      loading: false,

      form: {
        selBaseSence: [],
        farmId: "",
        sceneId: "",
        groupName: "",
        name: "",
        selSensorType: [],
        sensorType: "",
        sensorCategory: "",
        unicom: "",
        unicomGuid: "",
        unicomName: "",
        channelTypeArr: "",
        channelName: "",
        channelNo: 0,
        channelType: "",
        categoryMinRange: 0,
        categoryMaxRange: 0,
        minRange: 0,
        maxRange: 0,
        minRangeMicro: 0,
        maxRangeMicro: 0,
        percentage: "",
        channelNoNode: 0,
        num: "",
        remark: "",
        guid: "",
        id: "",
      },
      rules: {
        selBaseSence: [
          { required: true, type: "array", message: "请选择基地场景" },
        ],
        groupName: [{ required: true, message: "请填写监测点名称" }],
        name: [{ required: true, message: "请填写监测名称" }],
        selSensorType: [
          { required: true, type: "array", message: "请选择场景类型/监测类别" },
        ],
        unicom: [{ required: true, message: "请选择罗拉设备" }],
        channelNo: [{ required: true, message: "请填写通道号" }], //通道号
        channelNoNode: [{ required: true, message: "请填写通道号节点" }], //通道号节点
        channelTypeArr: [{ required: true, message: "请选择通道类型" }],
        categoryMinRange: [
          { required: true, message: "请填写监测值范围" },
          {
            validator(rule, value, callback) {
              if (
                !CU.validateNumber(value, null) ||
                !CU.validateNumber(_self.form.categoryMaxRange, null)
              ) {
                callback(rule.message1);
                return;
              }
              if (_self.form.categoryMaxRange < value) {
                callback(rule.message2);
                return;
              }
              callback();
            },
            message1: "请填写正确的监测值范围",
            message2: "最小范围不能大于最大范围",
          },
        ],
        minRange: [
          { required: true, message: "请填写量程范围" },
          {
            validator(rule, value, callback) {
              if (
                !CU.validateNumber(value, null) ||
                !CU.validateNumber(_self.form.maxRange, null)
              ) {
                callback(rule.message1);
                return;
              }
              if (_self.form.maxRange < value) {
                callback(rule.message2);
                return;
              }
              callback();
            },
            message1: "请填写正确的量程范围",
            message2: "最小范围不能大于最大范围",
          },
        ],
        minRangeMicro: [
          { required: true, message: "请填写微安值范围" },
          {
            validator(rule, value, callback) {
              if (
                !CU.validateNumber(value, null) ||
                !CU.validateNumber(_self.form.maxRangeMicro, null)
              ) {
                callback(rule.message1);
                return;
              }
              if (_self.form.maxRangeMicro < value) {
                callback(rule.message2);
                return;
              }
              callback();
            },
            message1: "请填写正确的微安值范围",
            message2: "最小范围不能大于最大范围",
          },
        ],
        percentage: [
          { required: true, message: "请填写百分比" },
          {
            validator(rule, value, callback) {
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请填写正确的百分比",
          },
        ],
        num: [
          { required: true, message: "请填写读取位数" },
          {
            validator(rule, value, callback) {
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请填写正确的读取位数",
          },
        ],
      },
    };
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.sensorTypeOptions = this.sensorType;
        this.getLlOptions();
        this.getChannelType();
        if (this.row) {
          for (let key in this.row) {
            this.form[key] = this.row[key];
          }
          this.form.selBaseSence = [this.row.farmId, this.row.farmSceneId];
          this.form.selSensorType = [
            this.row.sensorType,
            this.row.sensorCategory,
          ];
          this.form.id = this.row.id;
          // this.form.channelNo= this.row.channelNo;
          // this.form.channelNoNode = this.row.channelNoNode;
          this.form.channelTypeArr = `${this.row.channelType}/${this.row.channelTypeName}`;
          this.form.unicom = `${this.row.unicomGuid}/${this.row.unicomName}`;
          this.form.categoryMaxRange = +this.row.categoryMaxRange;
          this.form.categoryMinRange = +this.row.categoryMinRange;
          let obj = JSON.parse(this.row.computeJson);
          if (this.row.channelType == 4) {
            this.form.minRange = 0;
            this.form.maxRange = 0;
            this.form.minRangeMicro = 0;
            this.form.maxRangeMicro = 0;
            this.form.percentage = obj.percentage;
            this.form.num = obj.num;
          } else if (this.row.channelType == 1) {
            this.form.minRange = Number(obj.minRange);
            this.form.maxRange = Number(obj.maxRange);
            this.form.minRangeMicro = Number(obj.minRangeMicro);
            this.form.maxRangeMicro = Number(obj.maxRangeMicro);
          }
        }
      } else {
        this.form = {
          selBaseSence: [],
          farmId: "",
          sceneId: "",
          groupName: "",
          name: "",
          selSensorType: [],
          sensorType: "",
          sensorCategory: "",
          unicom: "",
          unicomGuid: "",
          unicomName: "",
          channelTypeArr: "",
          channelName: "",
          channelNo: "", //通道号
          channelNoNode: 0, //通道号节点
          channelType: "",
          categoryMinRange: 0,
          categoryMaxRange: 0,
          minRange: 0,
          maxRange: 0,
          minRangeMicro: 0,
          maxRangeMicro: 0,
          percentage: "",
          num: "",
          remark: "",
          guid: "",
          id: "", //修改id
        };
        this.$refs.form.resetFields();
        this.sensorTypeOptions = [];
        this.llOptions = [];
        this.channelTypeOptions = [];
      }
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.form.selBaseSence[0];
        params.sceneId = this.form.selBaseSence[1];
        params.unicomGuid = this.form.unicom.split("/")[0];
        params.unicomName = this.form.unicom.split("/")[1];
        params.channelType = this.form.channelTypeArr.split("/")[0];
        params.channelName = this.form.channelTypeArr.split("/")[1];
        params.sensorType = this.form.selSensorType[0];
        params.sensorCategory = this.form.selSensorType[1];
        delete params.selBaseSence;
        delete params.unicom;
        delete params.selSensorType;
        delete params.channelTypeArr;
        if (params.channelType == 4) {
          params.minRange = "";
          params.maxRange = "";
          params.minRangeMicro = "";
          params.maxRangeMicro = "";
        } else if (params.channelType == 1) {
          params.percentage = "";
          params.num = "";
        }
        this.loading = true;
        this.$post(
          params.id
            ? this.$api.BUSSDEVICESENSOR.UPDATE
            : this.$api.BUSSDEVICESENSOR.ADD,
          params
        )
          .then(() => {
            this.$emit("add-success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 获取监测类别
    getSensorCategoryList(item, cb) {
      item.loading = true;
      var name = "";
      switch (item.value) {
        case 1:
          name = "种植业传感器分类";
          break;
        case 2:
          name = "养殖业传感器分类";
          break;
        case 3:
          name = "水产业传感器分类";
          break;
      }
      this.$post(this.$api.DICT.SYS_LIST, {
        name,
      })
        .then((resp) => {
          let sensorCategoryOptions = [];
          for (let val of resp) {
            sensorCategoryOptions.push({
              value: val.num,
              label: val.name,
            });
          }
          item.children = sensorCategoryOptions;
          item.loading = false;
          cb();
        })
        .catch((e) => {
          item.loading = false;
          cb();
        });
    },
    //获取罗拉设备
    getLlOptions() {
      this.$post(this.$api.BUSSDEVICELUOLA.LIST, {
        pageSize: 9999,
        pageNo: 1,
      }).then((resp) => {
        if (!resp) return;
        this.llOptions = resp.list.map((el) => ({
          label: el.name,
          value: el.guid,
        }));
      });
    },
    //获取设备厂商
    getChannelType() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "设备厂商",
      }).then((resp) => {
        this.channelTypeOptions = resp.map((item) => {
          return {
            value: item.num,
            label: item.name,
            // children: [],
            loading: false,
          };
        });
      });
    },

    getChannelList(item, cb, { id, type, no, name, guid }) {
      // 后台并未返回已绑定的通道，所以编辑填充表单时需要手动unshift列表中存在的通道数据
      item.loading = true;
      let api = "";
      let params = {
        sceneId: this.form.selBaseSence[1],
        isNotStatus: 1,
      };
      switch (item.value) {
        case 1:
          api = this.$api.AISLE.JY_AISLELIST;
          params = {
            ...params,
            doType: 0,
            pageNo: 1,
            pageSize: 99999,
          };
          break;
        case 4:
          api = this.$api.AISLE.SBW_AISLELIST;
          params = {
            ...params,
            pageNo: 1,
            pageSize: 99999,
          };
      }
      this.$post(api, {
        ...params,
      })
        .then((resp) => {
          if (item.value === 1) {
            item.children = resp.list.map((item) => {
              return {
                value: item.guid,
                label: item.channelName,
                num: item.channelNo,
              };
            });
            id &&
              item.children.unshift({
                value: guid,
                label: name,
                num: +no,
              });
          } else if (item.value === 4) {
            item.children = resp.list.map((item) => {
              return {
                value: item.guid,
                label: item.nodeName,
                num: item.nodeAddress,
              };
            });
            id &&
              item.children.unshift({
                value: guid,
                label: name,
                num: +no,
              });
          }

          item.loading = false;
          cb();
        })
        .catch((e) => {
          item.loading = false;
          cb();
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>